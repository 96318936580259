// src/components/ApologyBot.js

import React, { useState } from 'react';
import { motion } from 'framer-motion';
import './Styles/Apology.css';
import {Link} from 'react-router-dom';


const Apology = ({gotoNext}) => {

  return (

    // <div className="bg" style={{backgroundImage: `url('${background}')`}}> 

<div className='container'>
     <div>
      <h1 className = "header">Welcome to Mail Box</h1>
     </div> 

      <div className='gif_cont'>
        <img src='https://media.tenor.com/qVdyIgm8wzYAAAAi/hello.gif' alt='gif1'></img>
      </div>
      
      <div className='proceed'>
        {/* <Link to='/main'> */}
          <button className='btn btn-light' id='buton' onClick={gotoNext}>Proceed</button>
        {/* </Link> */}
      </div>
  </div>
  // </div>

  );

};

export default Apology;
