// App.js

import React, {useState} from 'react';
import { BrowserRouter as Router, Route, Switch, Routes } from 'react-router-dom';
import Apology from './Apology.js';
import './Styles/App.css';
import Main from './Main.js';
// import { Route, Router } from 'react-router-dom';
import background from './background.jpg';


function App() {

  const [currentPage, setCurrentPage] = useState('home');

  const gotoNext = () => {
    setCurrentPage('next');
  }

  const gotoHome = () => {
    setCurrentPage('home');
  }

  return (
    <div className='app'>
        <div className="bg" style={{backgroundImage: `url('${background}')`}}> 
    {/* <Router>
        <Routes> */}
          {currentPage == 'home' && <Apology gotoNext={gotoNext} />}
          {currentPage == 'next' && <Main gotoHome={gotoHome}/>}
        {/* </Routes>
    </Router> */}
    </div>
      </div>
      
  );
}

export default App;