import React from "react";
import './Styles/Main.css'

const Main = ({gotoHome}) => {
    return (
        <div className="container" id='content'>
            <div className="col-12 text-center pt-3">
                <h1> LetterBox-1 </h1>
            </div>

            <div className="row">
                <div className="col-3 justify-content-start">
                    <img src="https://media.tenor.com/Ex1pkci_-v8AAAAi/white-cute-cat-hearts.gif"></img>
                    <button className="btn btn-light" id="buton" onClick={gotoHome}>Back</button>
                </div>

                <div className="col-9" >
                    <div className='img-container'>
                        <img src="./letter1.jpg" alt="1."></img>
                        <img src="./letter2.jpg" alt="2."></img>
                    </div>
                </div>
            </div>
            
        </div>
    );
};

export default Main;